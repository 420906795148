@import (css) url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

/** custom vars **/
@dash-stroke: "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='1' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e";
@gutter: 20px;
@sm-gutter: 10px;
@border-radius: 10px;

@white: #fff;
@red: var(--rs-red-500);
@green: var(--rs-green-500);
@lightgreen: var(--rs-green-50);
@darkgray: var(--rs-gray-600);
@gray: var(--rs-gray-300);
@lightgray: var(--rs-gray-50);;
@purple: var(--rs-violet-400);
@yellow: var(--rs-yellow-400);
@blue: var(--rs-blue-500);
@bluetext: var(--rs-blue-700);
@lightblue: var(--rs-blue-50);

/** Rewrite **/
body {
  font-family: "Montserrat", sans-serif;
  color: #484554;
  background-color: #F0F0F0;
}
h2 { font-size: 20px; line-height: 1.2em; }
h3 { font-size: 16px; line-height: 1.2em; }
h4 { font-size: 15px; line-height: 1.2em; }
h6 { font-size: 15px; font-weight: 500; }
p { margin-bottom: 5px; }
.rs-btn-ghost {
  border: 0;
  color: inherit;
}
.rs-sidenav-nav > .rs-sidenav-item > svg {
  margin-right: @gutter;
  position: absolute;
  left: @gutter;
}
.rs-message-container { margin: 5px 0; }
.caps-sidenav {
  // .rs-sidenav-body { margin-top: @sm-gutter; }
  .rs-sidenav-item {
    background-color: @white;
  }
}
.rs-sidenav-toggle-button {
  background-color: transparent !important;
}
.rs-navbar-item {
  padding-left: 13px; padding-right: 13px;
}
.rs-navbar-item span.rs-navbar-item-icon {
  display: inherit;
}
@media (max-width: 500px) {
  .rs-drawer-body { padding: 0 @gutter @gutter+@gutter @gutter; }
}
.rs-tree-node-label-content {
  padding: 0;
}

/** Message **/
.rs-message.sm > .rs-message-container {
  padding-top: 5px;
  padding-bottom: 5px;
}


/** Main layout **/
.caps-main {
  max-width: 1900px;
  margin-left: auto;
  margin-right: auto;
  padding: @gutter;
}
.caps-navbar {
  height: 77px;
  box-sizing: border-box;
  padding: @sm-gutter @gutter;
  background-color: @white;
  & > * {
    background-color: transparent;
  }
  .navbar-profile {
    padding: @gutter;
    background-color: @blue !important;
    color: @white !important;
  }
}
.caps-navbar-submenu {
}
.caps-main-flex {
  display: flex;
  justify-content: space-between;
  .caps-main-flex-left {
    padding-right: @gutter;
    flex-basis: 300px;
    min-width: 300px;
    &.close {
      flex-basis: auto;
      min-width: auto;
    }
  }
  .caps-main-flex-content {
    flex-grow: 1;
    &.sm {
      max-width: calc(100% - 300px);
    }
  }
  .caps-main-flex-right {
    padding-left: @gutter;
    flex-basis: 440px;
    min-width: 440px;
  }
}
.caps-contentbar {
  padding: @gutter;
  background-color: @white;
}
.caps-content {
  background-color: @white;
}
.caps-header {
  margin: @gutter;
  min-height: 44px;
  > div {
    align-items: center;
    min-height: inherit;
  }
}
.caps-header.inner-header {
  background-color: @lightgray;
  padding: @sm-gutter;
  margin-left: 0;
  margin-right: 0;
}
.caps-header .caps-header__text {
  word-wrap: break-word;
  max-width: 100%;
}

/** Sidenav **/
.caps-sidenav {
  background-color: @white;
}

/** Card **/
.grid-items {
  align-items: stretch;
  row-gap: @sm-gutter;
}
.item-card {
  position: relative;
  min-height: 100px;
  height: calc(100% - 40px);
  padding: @gutter;
  background-color: @white;
  // margin: 0 0 @sm-gutter 0;
}
.item-card.hovered {
  border-bottom: 1px solid @gray;
  &:hover {
    background-color: #f2faff;
  }
}
.item-card-button {
  min-height: 120px;
  height: calc(100% - 20px);
  margin: 0;
  padding: 5px;
  padding: 10px;
  border-radius: 5px;
  background-image: url(@dash-stroke);
  &:hover {
    background-color: @white;
  }
  &.sm {
    min-height: 0;
    height: 50px;
    padding: 2px;
  }
}
  .item-card-button__create {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @white;
    cursor: pointer;
  }
    .item-card-button__icon {
      margin-right: 10px;
    }
  .item-card-menu {
    position: absolute;
    right: 0; top: 0;
    background-color: @lightblue;
  }
  .item-card-picture {
    display: flex;
    height: 150px;
    margin: -20px -20px 20px -20px;
    justify-content: center;
    overflow: hidden;
  }
  .item-card__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: @gutter;
    margin-top: -@gutter;
    margin-bottom: 10px;
    max-width: 450px;
    font-size: 14px;
    font-weight: 600;
    &>.item-card__header-badge {
      position: absolute;
      margin-left: -15px;
      margin-top: @gutter + 6px;
    }
    &>.item-card__header-title {
      margin-top: @gutter;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  // .item-card__body-picture {
  //   float: right;
  //   width: 90px;
  //   height: 50px;
  //   margin-left: 10px;
  //   overflow: hidden;
  //   background-color: @lightgray;
  // }
  .item-card__body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 6px;
  }
    .item-card__body-unit {
      font-weight: 500;
    }
    .item-card__body-date {
      font-size: 12px;
    }
    .item-card__pin {
      display: flex;
      cursor: pointer;
      padding: 0 5px;
      color: @bluetext;
    }
      .item-card__pin-code {
        text-decoration: underline;
        margin-left: 4px;
      }
  .item-card__body-sub {
    font-size: 13px;
  }
  .item-card__bottom {
    margin-top: 5px;
  }
.item-card.bg-card {
  padding: 0;
  height: 150px;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: @border-radius;
  > .bg-card__image {
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
  }
}
.item-card.ghost-card {
  background-color: #e5e5e5;
}
.button-card {
  padding: @gutter;
  text-align: center;
  transition: all .3s;
  &:hover {
    background-color: #ddd;
  }
}
.item-card-menu-action-delete {
  border-top: 1px solid @gray;
  color: @red;
}

/** FilesList **/
.caps-list-wrap {
  padding: @border-radius;
  background-color: @white;
}
.caps-list {
  padding: 10px;
  background-color: #fff;
}
.files-list {
  border-left: 1px solid #DEDEDE;
}
.item-list.grid {}
  .item-list__item {
    overflow: hidden;
    padding: 10px;
    &:nth-child(even) { background-color: @lightgray; }
  }
  .item-list__item:hover,
  .item-list__item.active {
    background-color: #08a0ff1a;
  }
    .item-list__item-content {
      overflow: hidden;
      &.with-badge {
        position: relative;
        padding-left: 20px;
      }
    }
      .item-list__item-content-badge {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }
      .item-list__item-content-icon {
        float: left;
        &>.rs-avatar {
          display: block;
        }
      }
      .item-list__item-content-text {
        padding-left: 10px;
        overflow: hidden;
        > .small {
          margin-top: 5px;
        }
      }
    .item-list__item-actions {
      float: right;
      width: 80px;
      overflow: hidden;
      text-align: right;
    }
.item-list.grid {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
}
  .item-list.grid .item-list__item {
    box-sizing: border-box;
    width: 33.3333%;
    border: 10px solid #fff;
    background-color: @lightgray;
  }
  @media (max-width: 1900px) {
    .item-list.grid .item-list__item {
      width: 50%;
    }
  }
  @media (max-width: 860px) {
    .item-list.grid .item-list__item {
      width: 100%;
    }
  }
.item-draggable {
  cursor: move;
  display: flex;
  align-items: center;
  padding: @sm-gutter @gutter;
  transition: all .3s;
  border: 1px solid @gray;
  background-color: @lightgray;
  &:hover {
    background-color: #ddd;
  }
  &.item-draggable-index-null,
  &.item-draggable-hightlight {
    background-color: @lightgreen;
    border-color: @green;
  }
}

/** Player **/
.windows-canvas {
  position: relative;
  background-color: #ddd;
  & > div {
    position: absolute;
    background-color: rgba(46, 172, 88, 0.6);
  }
}

/** Form **/
.caps-select {
  max-width: 220px;
  select {
    width: auto !important;
  }
}

/** Campaign Form **/
.carousel-container {
  overflow: auto;
  .carousel-list {
    list-style: none;
    margin: 0 0 8px 0;
    padding: 0;
    white-space: nowrap;
    width: 100%;
    li {
      display: inline-block;
      margin: 0 2px;
      padding: 10px;
      background-color: @lightgray;
      & > * {
        vertical-align: middle;
      }
      &.active {
        background-color: @blue;
        color: @white;
      }
      &.ghost {
        padding: 0;
        background-color: transparent;
      }
      &.link {
        margin-left: 10px;
        background-color: @white;
        border-radius: @border-radius;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  .carousel-list.block-list {
    white-space: normal;
    margin: 0;
    li {
      display: block;
    }
  }
}
.carousel-container {
  scrollbar-width: thin;
  scrollbar-color: @gray @white;
}
.carousel-container::-webkit-scrollbar {
  width: 12px;
}
.carousel-container::-webkit-scrollbar-track {
  background: @white;
}
.carousel-container::-webkit-scrollbar-thumb {
  background-color: @gray;
  border-radius: 20px;
  border: 3px solid @white;
}
// hide
.carousel-container.hide-scroll::-webkit-scrollbar {
  display: none;
}
.carousel-container.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.campaigns-form_content-items-available,
.campaigns-form_content-items-selected {
  height: 320px;
  overflow-y: auto;
  background-color: @lightblue;
}
.campaigns-form_content-items-selected {
  background-color: @lightgreen;
}
.campaigns-form_content-items-item {
  white-space: normal;
  min-width: 80px;
}
.campaigns-content-item-disable {
   margin: 0 -5px 0 -5px;
   padding: 5px;
   background-color: #a6d7ff
}

/** Stat **/
.stat-item {
  vertical-align: middle;
  margin-bottom: 20px;
  padding: 20px;
  background-color: @lightgray;
}
  .stat-item__circle {
    position: relative;
    float: left;
    width: 67px;
    height: 67px;
  }
  .stat-item__value {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 67px;
    font-size: 16px;
    font-weight: 600;
  }
  .stat-item__label {
    overflow: hidden;
    padding-left: 20px;
    line-height: 67px;
    font-size: 16px;
    font-weight: 600;
  }

/** Settings **/
.setting-radio-header {
  margin: 15px 0;
  padding: 15px 0;
  font-weight: bold;
  border-top: 2px solid @gray;
  border-bottom: 2px solid @gray;
}
.setting-radio-group {
  padding: 5px;
  align-items: center;
  &:hover {
    background-color: @gray;
  }
}

/** FilesForm **/
.caps-file-uploader {
  .rs-uploader-file-item-text {
    padding-left: 10px;
    border-bottom: 1px solid #DEDEDE;
    .rs-uploader-file-item-content {
      display: block;
    }
    .rs-uploader-file-item-size {
      position: absolute;
      bottom: 10px; left: 1px;
      background-color: #fff;
      font-size: 12px;
      color: #484554;
    }
  }
}

.caps-tree {
  .rs-search-box {
    margin-bottom: 20px;
  }
  .rs-check-tree-node .rs-checkbox-label {
    background-color: transparent !important;
    padding: 0 !important;
    overflow: visible !important;
    white-space: normal;
  }
  .caps-tree-node {
    display: flex;
    align-items: center;
  }
  .rs-inline-edit {
    min-width: 160px;
  }
}

/** Border radius **/
.caps-navbar,
.caps-sidenav,
.rs-sidenav-toggle-button,
.item-card,
.caps-contentbar,
.caps-list-wrap,
.stat-item,
.item-draggable {
  border-radius: @border-radius;
}

/** Responsive **/
.caps-main.caps-mobile {
  padding-left: @sm-gutter;
  padding-right: @sm-gutter;
  .caps-navbar {
    margin: -@gutter -@sm-gutter 0 -@sm-gutter;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 0;
  }
  .caps-header {
    margin-top: 0;
    margin-left: @sm-gutter;
    margin-right: @sm-gutter;
  }
  .caps-contentbar {
    padding-left: @sm-gutter;
    padding-right: @sm-gutter;
  }
  .stat-item {
    padding: 0;
    background-color: transparent;
  }
  .stat-item__circle {
    float: none;
    margin: 0 auto;
  }
  .stat-item__label {
    padding-left: 0;
    line-height: normal;
    text-align: center;
    font-size: 13px;
    font-weight: normal;
  }
}
.fab-button {
  position: fixed;
  right: @gutter;
  bottom: @gutter;
}

/** Utils **/
.flex-image {
  max-width: 100%;
  height: auto;
  display: block;
}
.caps-table {
  font-size: 13px;
  width: 100%;
  tr:nth-child(even) { background-color: @lightgray; }
  tr:hover { background-color: @gray; }
  tr > td { padding: 8px 15px; }
  // tr > td:first-child { padding-left: 0; }
}
.space-between {
  display: flex;
  justify-content: space-between;
}
.badge {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: @purple;
  border-radius: 10px;
  &.badge-yellow { background-color: @yellow; }
  &.badge-green { background-color: @green; }
  &.badge-gray { background-color: @gray; }
  &.badge-red { background-color: @red; }
}
.small {
  font-size: 10px;
  color: #888888;
}
.border-bottom { border-bottom: 1px solid @gray; }
.space-around { justify-content: space-around; }
.inner-form { margin-bottom: 10px; }
.inner-block { margin-bottom: @gutter; }
.text-right { text-align: right; }
.text-center { text-align: center; }
.pull-left { float: left; }
.inline-block { display: inline-block; }
.round { border-radius: @border-radius; }
.ml-0 { margin-left: 0 !important; }
.mb-0 { margin-bottom: 0; }
.mr-10 { margin-right: 10px; }
.ml-10 { margin-left: 10px; }
.mb-5 { margin-bottom: 5px; }
.mb-10 { margin-bottom: 10px; }
.mt-10 { margin-top: 10px; }
.mr-20 { margin-right: 20px; }
.ml-20 { margin-left: 20px; }
.mb-20 { margin-bottom: 20px; }
.mt-20 { margin-top: 20px; }
.p-10 { padding: 10px; }
.p-20 { padding: 20px; }
.width-100 { width: 100%; }
.border-highlight { border: 1px solid var(--rs-btn-primary-bg); }
.white { color: @white; }
.green { color: @green; }
.red { color: @red; }

.bgm-red { background-color: @red; }
.bgm-pink { background-color: #e91e63; }
.bgm-purple { background-color: #9c27b0; }
.bgm-deeppurple { background-color: #673ab7; }
.bgm-blue { background-color: @blue; }
.bgm-green { background-color: @green; }

.rotate-45 { transform: rotate(45deg) translateY(3px); }

/** Animations **/
.spin-animations-infinite {
  display: inline-flex;
  animation: spin 5s ease-in-out infinite;
}
.ring-animations-infinite {
  display: inline-flex;
  animation: ring 4s ease-in-out infinite;
  transform-origin: 50% 5px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(720deg); }
  100% { transform: rotate(720deg); }
}
 
@keyframes ring {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }
  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}



.analytic-page {
  padding-right: 10px;
}
.analytic-device-body {
  min-height: 195px;
  margin-bottom: @gutter;
  padding: @gutter;
  background-color: @white;
  border-radius: 10px;
  .children {
    margin-left: 30px;
  }
}
  .analytic-body-inner {
    padding-top: 50px;
    padding-bottom: 5px;
    position: relative;
    white-space: nowrap;
    overflow: auto;
  }
  .analytic-body-spin {
    position: absolute;
    left: 0; right: 0;
    bottom: 0; top: 0;
    padding-top: 90px;
    text-align: center;
    background-color: rgba(255,255,255,0.5);
    z-index: 1;
  }
  .analytic-player-name {
    position: absolute;
    top: 0;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .analytic-month-name {
    position: absolute;
    display: inline-block;
    top: 25px;
    font-weight: 500;
    padding-left: 10px;
  }
  .week-td {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    width: 42px;
    border: 2px solid @white;
    cursor: pointer;
    &.day-01.longer::before {
      content: '';
      position: absolute;
      left: -18px;
      top: 0;
      height: 100%;
      border-left: 2px dashed #e5e5e5;
    }
  }
  .analytic-device-body .longer {
    margin-left: 75px;
  }
  .analytic-dayofweek .week-td {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .week-td.disabled {
    border: none;
    width: 100px;
  }
  .week-td-filled {
    color: #606060;
  }
  .week-td-filled:hover {
    background-color: @lightgray;
  }
  .week-td-filled.active {
    background-color: var(--rs-gray-50);
    border: 2px dotted var(--rs-green-300);
    border-radius: 10px;
  }
    .week-td-week {
      font-size: 12px;
      text-transform: lowercase;
      background-color: @lightgray;
      border-radius: 10px 10px 0px 0px;
    }
    .week-td-day {
      margin-bottom: 10px;
      background-color: @lightgray;
      border-radius: 0 0 10px 10px;
    }
    .week-td-percent {
      position: relative;
      height: 120px;
    }
      .week-td-percent > span {
        display: block;
        position: absolute;
        left: 8px; right: 8px; bottom: 0;
        line-height: 20px;
        border-radius: 5px 5px 0px 0px;
      }
      .week-td-percent-val {
        margin-top: 5px;
      }
@base-color: #f44336;